import * as React from 'react';
import { components } from 'react-select';
import { t } from 'i18next';
import {
  OptionWrapper,
  MenuButton,
  FormatLabelWrapper,
} from './CustomComponents.styles';

export const customOptionComponent = () => {
  const Option = (props) => (
    <OptionWrapper>
      <components.Option {...props}>
        <input
          type="checkbox"
          defaultChecked={props.isSelected}
          checked={props.isSelected}
          className={props.isDisabled && 'disabled'}
          onClick={(e) => {
            if (props.isDisabled) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </OptionWrapper>
  );

  return Option;
};

export const customMenuComponent = () => {
  const MenuComponent = (props) => (
    <>
      <components.Menu {...props}>
        {props.children}
        <MenuButton onClick={props.selectProps.onApplyFilters}>
          {t('Apply filters')}
        </MenuButton>
      </components.Menu>
    </>
  );

  return MenuComponent;
};

export const customValueContainerComponent = () => {
  const getLabel = (length, values) => {
    if (values[0]?.props?.children === 'All tiles') {
      return t('All tiles');
    }

    if (length === 1) return t('{{length}} tile selected', { length });

    return t('{{length}} tiles selected', { length: length || 0 });
  };

  const ValueContainer = (props) => (
    <components.ValueContainer {...props}>
      {getLabel(props.children[0].length, props.children[0])}
    </components.ValueContainer>
  );

  return ValueContainer;
};

export const formatGroupLabel = (data) => {
  if (data.label)
    return (
      <FormatLabelWrapper>
        <span>{data.label}</span>
      </FormatLabelWrapper>
    );
  return null;
};
